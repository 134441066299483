<template>
  <div>
    <el-dialog v-model="dialogVisible" width="1100px" append-to-body :show-close="false">
      <template #title>
        <div class="dialog_box">
          <div class="con_title"><span></span> 选择采购验收单</div>
        </div>
        <div class="new_search_box">
          <div style="margin-top:0px">
              <span class="mr_20">
                编号：
                <el-input size="mini"
                  v-model="queryParams.purchase_acceptance_no"
                  placeholder="请输入编号"
                  style="width:150px"
                />
              </span>
              <span class="mr_20">
                供货单位：
                <el-select size="mini" v-model="queryParams.merchant_id" placeholder="输入供货单位名称或助记码" clearable remote :remote-method="remoteMethod"
                    style="width:200px" filterable>
                    <el-option :label="item.merchant_name" v-for="(item,index) in jhdwlist"
                        :key="index" :value="item.id"></el-option>
                </el-select>
              </span>
              <span class="mr_20">
                时间：
                <el-date-picker size="mini" 
                  v-model="queryParams.time"
                  type="daterange"  value-format="YYYY-MM-DD" 
                  placeholder="请选择时间"
                  range-separator="至" style="width: 300px;"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </span>
            <el-button type="primary" size="mini"   @click="handleQuery()">搜索</el-button>
            <el-button size="mini" @click="resetQuery()">重置</el-button>
          </div>
        </div>
      </template>
      <el-table
        :data="list"  v-loading="loading"  
        style="width: 100%" ref="tableDataRefs" 
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" :selectable="selectable_func" />
        <el-table-column prop="purchase_acceptance_date" label="开票时间" />
        <el-table-column prop="purchase_acceptance_no" label="单据编号" />
        <el-table-column prop="department_name" label="部门" />
        <el-table-column prop="admin_name" label="采购员" />
        <el-table-column prop="mebl_operation_mode" label="联系电话" />
        <el-table-column prop="merchant_no" label="单位编号" />
        <el-table-column prop="merchant_name" label="供货单位" />
        <!-- <el-table-column prop="merchant_name" label="产品编号" /> -->
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        v-model:page="queryParams.page"
        v-model:limit="queryParams.size"
        @pagination="getList"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :disabled="multiple" @click="confirm_sele">确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'selectpurchasePlan',
  props: {
    
  },
  data() {
    return {
        // 列表模板
        loading: false,
        // 查询参数
        queryParams: {
            page: 1,
            size: 10,
            purchase_acceptance_no: '',
            time:''
        },
        total:0,
        list:[],
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        dialogVisible:false,
        disabled_ids:[],  // 上级页面已选择的，这里不能再选
        jhdwlist:[]
    }
  },
  
  methods: {
    init(ids){
        this.dialogVisible=true;
        this.queryParams={
            page: 1,
            size: 10,
            purchase_acceptance_no: '',
            time:''
        }
        this.disabled_ids=ids || [];
        this.getList();
        this.remoteMethod()
    },
    resetQuery(){
        this.queryParams= {
            page: 1,
            size: 10,
            purchase_acceptance_no: '',
            time:''
        }
        this.getList()
    },
    remoteMethod(keyword){
        this.$httpGet("/backend/Merchant/merchant_purchase", {size:10,title:keyword}).then((res) => {
            if (res.status == 200) {
                this.jhdwlist = res.data.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    confirm_sele(){
        let arr=[];
        for(let i=0;i<this.list.length;i++){
            if(this.ids.indexOf(this.list[i].id)!=-1){
                arr.push(this.list[i])
            }
        }
        this.$emit('after_sele',arr);
        this.dialogVisible=false;
    },
    // 已选的id禁选
    selectable_func(row,index){
        if(this.disabled_ids.indexOf(row.id)!=-1){
            return false
        }
        return true
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      // this.ids = selection.map(item => item.id);
      // 判断长度等于1 获取
      if (selection.length <= 1) {
        this.ids = selection.map(item => item.id);
      }
      if (selection.length > 1) {
        this.ids = [selection[1].id]
        let del_row = selection.shift()
        this.$refs.tableDataRefs.toggleRowSelection(del_row, false)
      }
      this.single = this.ids.length != 1;
      this.multiple = !this.ids.length;
    },
    /** 查询列表 */
    getList() {
        this.loading = true;
        let _data={...this.queryParams};
        _data.startTime=_data.time?_data.time[0]:'';
        _data.endTime=_data.time?_data.time[1]:'';
        this.$httpGet("/backend/PurchaseAcceptance/acceptance_index", _data).then((res) => {
            if (res.status == 200) {
                this.loading = false;
                this.list = res.data.data;
                this.total = res.data.total;
            } else {
                this.$message.error(res.message);
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
    },
    /** 搜索按钮操作 */
    handleQuery() {
        this.queryParams.page = 1;
        this.getList();
    }
  }
}
</script>

<style scoped>
.dialog_box {
  display: flex;
  justify-content: space-between;
}
 /deep/ .el-table__header-wrapper .el-checkbox {
  display: none;
}
</style>
